<template>
    <div class="model-edit-page">
        <el-row>
            <el-col :span="3">
                <div>基本信息：</div>
            </el-col>
            <el-col :span="21">
                <el-form ref="modelEditForm" :model="modelEditForm" :rules="rules" label-width="100px">
                    <el-form-item label="模型体系：">
                        <el-row>
                            <el-col :span="8">
                                <el-form-item prop="system">
                                    <el-select size="small" placeholder="请选择体系" clearable v-model="modelEditForm.system"
                                        @change="changeClassificationId">
                                        <el-option v-for="item in systemList" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" style="padding-left: 20px">
                                <el-form-item prop="classification">
                                    <el-select size="small" placeholder="请选择分类" :popper-append-to-body="false" clearable
                                        v-model="modelEditForm.classification" @change="changeMaterialId">
                                        <el-option v-for="item in classificationList" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8" style="padding-left: 20px" v-if="materialList.length > 0">
                                <el-form-item>
                                    <el-select size="small" placeholder="请选择材料" :popper-append-to-body="false" clearable
                                        v-model="modelEditForm.material">
                                        <el-option v-for="item in materialList" :key="item.id" :label="item.name"
                                            :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="品牌：">
                        <el-select style="width: 290px" size="small" v-model="modelEditForm.brandId" clearable
                            placeholder="请选择品牌">
                            <el-option v-for="(item, index) in brandList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="模型名称：" prop="modelName">
                        <el-input size="small" style="width: 290px" maxlength="20" v-model="modelEditForm.modelName"
                            @blur="handleModelNameBlur"></el-input>
                        <span style="margin-left: 10px; color: red">{{ errorMessage }}</span>
                    </el-form-item>
                    <el-form-item>
                        <div class="btn" @click="handleEditModelBtn">编辑模型</div>
                        <div class="btn" @click="handleEditSaveBtn">保存</div>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <EditModel :dialogVisible="dialogModelEditVisible" v-if="dialogModelEditVisible" @closeDialog="closeDialog">
        </EditModel>

    </div>
</template>

<script>
import jsonTool from '@/utils/jsonTool.js';
import { getAttributesData, modelNameRecheckAction } from '@/common/api/model/modelUpload/index.js';
import { modelEditAction } from '@/common/api/model/modelLibrary/index.js';
import EditModel from "../../modelUpload/components/editModel";
export default {
    name: 'modelEditDialog',
    props: {
        modelEditData: {
            type: Object,
            default: () => { }
        }
    },
    components: { EditModel },
    data() {
        var validateSystem = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('请选择体系'));
            } else {
                callback();
            }
        };
        var validateClassification = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('请选择分类'));
            } else {
                callback();
            }
        };
        var validateModelName = (rule, value, callback) => {
            if (value === '') {
                return callback(new Error('请输入模型名称'));
            } else {
                callback();
            }
        };
        return {
            dialogModelEditVisible: false,
            modelEditForm: {
                id: '',
                // 体系
                system: '',
                // 分类
                classification: '',
                // 材料
                material: '',
                // 品牌名称
                brandId: '',
                // 模型名称
                modelName: ''
            },
            // 模型名称错误信息提示
            errorMessage: '',
            rules: {
                system: [{ validator: validateSystem, trigger: 'change' }],
                classification: [{ validator: validateClassification, trigger: 'change' }],
                modelName: [
                    { validator: validateModelName, trigger: 'blur' },
                    {
                        pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/,
                        message: '只允许输入：字母/数字/汉字/下划线',
                        trigger: 'blur'
                    }
                ]
            },
            // 系统列表
            systemList: [],
            // 分类列表
            classificationList: [],
            // 材料列表
            materialList: [],
            // 品牌列表
            brandList: []
        };
    },
    mounted() {
        this.queryAttributesData();
        this.modelEditForm = this.modelEditData;
    },
    methods: {
        closeDialog(val) {
            this.dialogModelEditVisible = val;
        },
        init() {
            this.changeClassificationId();
            this.changeMaterialId();
        },
        // 查询体系/分类/材料/品牌数据
        queryAttributesData() {
            getAttributesData()
                .then(res => {
                    let { code, result } = res;
                    if (code === 200) {
                        let newResult = jsonTool(result);
                        this.systemList = newResult.setupList.map(item => {
                            return {
                                id: item.id,
                                name: item.setupName,
                                materialList: item.materialList
                            };
                        });
                        // console.log('体系this.systemList: ', this.systemList);
                        this.brandList = newResult.brandList.map(item => {
                            return {
                                id: item.bId,
                                name: item.bName
                            };
                        });
                        // 回显分类/材料
                        this.init();
                        // console.log('this.brandList: ', this.brandList);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理选择分类
        changeClassificationId() {
            this.systemList.forEach(ele => {
                if (ele.id === this.modelEditForm.system) {
                    this.classificationList = ele.materialList.map(item => {
                        return {
                            id: item.id,
                            name: item.materialName,
                            thicknessList: item.thicknessList
                        };
                    });
                    // console.log('分类this.classificationList: ', this.classificationList);
                }
            });
        },
        // 处理选择材料
        changeMaterialId() {
            this.classificationList.forEach(ele => {
                if (ele.id === this.modelEditForm.classification) {
                    let arr = [];
                    ele.thicknessList.forEach(v => {
                        if (Number(v.thicknessSize) === 0) {
                            arr.push(v);
                        }
                    });
                    this.materialList = arr.map(item => {
                        return {
                            id: item.id,
                            name: item.thicknessName,
                            wildcardList: item.wildcardList,
                            thicknessSize: item.thicknessSize
                        };
                    });
                }
            });
        },
        // 处理模型输入框失去焦点事件
        handleModelNameBlur() {
            let params = {
                name: this.modelEditForm.modelName
            };
            modelNameRecheckAction(params)
                .then(res => {
                    let { code } = res;
                    if (code === 444) {
                        this.errorMessage = `当前模型名称已存在，请重新输入`;
                    } else {
                        this.errorMessage = '';
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理编辑模型按钮
        handleEditModelBtn() {
            this.dialogModelEditVisible = true;
        },
        // 处理编辑保存按钮
        handleEditSaveBtn() {
            // 1模型列表  2待审核 3问题模型
            let listType = this.modelEditForm.activeName == 'modelIndex' ? 1 : 3;
            this.$refs.modelEditForm.validate(valid => {
                if (valid) {
                    let params = {
                        id: this.modelEditForm.id,
                        brand_id: this.modelEditForm.brandId,
                        list_type: listType,
                        name: this.modelEditForm.modelName,
                        setup_id: this.modelEditForm.system,
                        classify_id: this.modelEditForm.classification,
                        son_classify_id: this.modelEditForm.material
                    };
                    modelEditAction(params)
                        .then(res => {
                            let { code, msg } = res;
                            if (code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: msg,
                                    center: true
                                });
                                this.$emit('closeDialog', false);
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    return false;
                }
            });
        }
    }
};
</script>

<style scoped>
.model-edit-page .btn {
    display: inline-block;
    width: 90px;
    height: 35px;
    background-color: rgba(85, 85, 85, 1);
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
}

.model-edit-page .btn:first-child {
    margin-right: 30px;
}

.model-edit-page :deep(.el-form-item:last-child) {
    margin-bottom: 0;
}
</style>