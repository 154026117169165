<template>
    <div class="model-library-page">
        <el-row style="padding: 15px" v-if="!isEdit">
            <el-form size="small" :inline="true" :model="modelLibraryForm" class="demo-form-inline">
                <el-form-item label="名称：">
                    <el-input style="width: 200px" v-model="modelLibraryForm.searchVal" clearable
                        placeholder="请输入编号或模型名称"></el-input>
                </el-form-item>
                <el-form-item label="日期：">
                    <el-date-picker style="width: 360px" v-model="modelLibraryForm.dateTimeArr" type="datetimerange"
                        value-format="X" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="体系：">
                    <el-cascader style="width: 180px" v-model="modelLibraryForm.system" :popper-append-to-body="false"
                        :options="systemList" :props="systemProps" clearable />
                </el-form-item>
                <el-form-item label="品牌：">
                    <el-select style="width: 120px" size="small" v-model="modelLibraryForm.brand" clearable
                        placeholder="请选择品牌">
                        <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态：" v-show="activeName == 'modelIndex'">
                    <el-select style="width: 120px" size="small" v-model="modelLibraryForm.status" clearable
                        placeholder="请选择状态">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right; margin-right: 0">
                    <div class="btn" @click="handleSearch">查询</div>
                    <!-- <div class="btn" @click="handleUploadModel" v-show="activeName == 'modelIndex'">上传模型</div> -->
                    <div class="btn" @click="handleGenerate" v-show="activeName == 'modelIndex'">生成版本配置</div>
                </el-form-item>
            </el-form>
            <div class="model-library-content-wrap" style="width: 100%">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="模型列表" name="modelIndex">
                        <div class="content-action-left">
                            <el-checkbox v-model="isCheckedAllModelIndex" @change="handleCheckAllChange"
                                :indeterminate="isIndeterminate">已选 {{ checkedCardIdList.length }} 项</el-checkbox>
                            <el-button class="bg-color" :disabled="checkedCardIdList.length === 0"
                                style="width: 90px; margin-left: 20px" size="small" type="info"
                                @click="handleEnableDisableBtn(1)">启用</el-button>
                            <el-button class="bg-color" :disabled="checkedCardIdList.length === 0"
                                style="width: 90px; margin-left: 20px" size="small" type="info"
                                @click="handleEnableDisableBtn(2)">禁用</el-button>
                        </div>
                        <div class="modelIndex-content-wrap">
                            <ul class="content-list">
                                <li class="content-item" v-for="(modelList, index) in modelIndexDataList" :key="index">
                                    <div class="top-wrap">
                                        <el-checkbox v-model="modelList.isChecked"
                                            @change="handleModelCardCheckbox(modelList)"></el-checkbox>
                                        <el-button size="small" type="text" @click="handleModelIndexEditBtn(modelList)">
                                            编辑</el-button>
                                    </div>
                                    <div class="image-wrap">
                                        <img :src="modelList.modelUrl" alt="" />
                                    </div>
                                    <div class="bottom-wrap">
                                        <p class="model-name">模型名称：{{ modelList.modelName }}</p>
                                        <p class="brand-name">品牌名称：{{ modelList.brandName }}</p>
                                    </div>
                                    <div class="prompt" v-if="modelList.status === 0">已禁用</div>
                                    <!-- <div class="prompt enable" v-else>已启用</div> -->
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="待审核" name="waitAudit">
                        <div class="waitAudit-content-wrap">
                            <ul class="content-list">
                                <li class="content-item" v-for="(waitList, index) in waitAuditDataList" :key="index">
                                    <div class="top-wrap">
                                        <span>{{ index + 1 }}</span>
                                    </div>
                                    <div class="image-wrap">
                                        <img :src="waitList.modelUrl" alt="" />
                                    </div>
                                    <div class="bottom-wrap">
                                        <p class="model-name">模型名称：{{ waitList.modelName }}</p>
                                        <p class="brand-name">品牌名称：{{ waitList.brandName }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="问题模型" name="questionModel">
                        <div class="questionModel-content-wrap">
                            <ul class="content-list">
                                <li class="content-item" v-for="(questionList, index) in questionModelDataList"
                                    :key="index">
                                    <div class="top-wrap">
                                        <span>{{ index + 1 }}</span>
                                        <el-button size="small" type="text"
                                            @click="handleQuestionModelEditBtn(questionList)">编辑</el-button>
                                    </div>
                                    <div class="image-wrap">
                                        <img :src="questionList.modelUrl" alt="" />
                                    </div>
                                    <div class="reject-reason-wrap">
                                        <p class="reject-reason">驳回原因</p>
                                        <el-tooltip class="item" effect="dark" :content="questionList.reason"
                                            placement="top">
                                            <span class="reason-desc">{{ questionList.reason }}</span>
                                        </el-tooltip>
                                    </div>
                                    <div class="bottom-wrap">
                                        <p class="model-name">模型名称：{{ questionList.modelName }}</p>
                                        <p class="brand-name">品牌名称：{{ questionList.brandName }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div style="text-align: right; margin-top: 30px; width: 100%">
                <el-pagination v-model:currentPage="page.page" :page-sizes="[10, 15, 30, 50]" :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.count" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
            <!-- 编辑弹窗 -->
            <dialogFrame :show="dialogModelEditVisible" @showDialog="showDialog" :title="'编辑'" :footer="false"
                width="750px" center>
                <modelEditDialog v-if="dialogModelEditVisible" :modelEditData="modelEditData"
                    @closeDialog="closeDialog"></modelEditDialog>
            </dialogFrame>
        </el-row>
        <ModelUploadCom :modelId="modelId" :listType="list_type" :buttonString="buttonString" :isEdit="isEdit"
            :title="title" v-else></ModelUploadCom>
    </div>
</template>
<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import modelEditDialog from './components/modelEditDialog.vue';
import jsonTool from '@/utils/jsonTool.js';
import { getAttributesGetModel } from '@/common/api/model/modelUpload/index.js';
import {
    getModelLibraryData,
    modelChangeStatusAction,
    modelGenerateConfig
} from '@/common/api/model/modelLibrary/index.js';
import ModelUploadCom from '../../../components/ModelUploadCom';

export default {
    name: 'model',
    components: {
        dialogFrame,
        modelEditDialog,
        ModelUploadCom
    },
    data() {
        return {
            title: '编辑模型信息',
            buttonString: '保存并编辑模型',
            modelLibraryForm: {
                // 名称
                searchVal: '',
                // 时间
                dataTimeArr: [],
                // 体系
                system: '',
                // 品牌
                brand: '',
                // 状态
                status: ''
            },
            systemProps: {
                multiple: true,
                checkStrictly: true,
                value: 'id',
                label: 'name',
                children: 'materialList'
            },
            activeName: 'modelIndex',
            list_type: 1,
            // 体系列表
            systemList: [],
            // 品牌列表
            brandList: [],
            // 状态列表
            statusList: [
                // {
                //     id: '',
                //     name: '全部'
                // }
            ],
            // 是否全选
            isCheckedAllModelIndex: false,
            // 部分勾选状态
            isIndeterminate: false,
            // 选中的卡片id集合
            checkedCardIdList: [],
            // 模型列表页面数据
            modelIndexDataList: [],
            // 是否显示模型列表编辑弹窗
            dialogModelEditVisible: false,
            // 模型列表编辑弹窗数据
            modelEditData: {},
            //待审核页面数据
            waitAuditDataList: [],
            // 问题模型页面数据
            questionModelDataList: [],
            page: {
                page: 1,
                pageSize: 10,
                count: 0
            },
            isEdit: false,
            modelId: 0
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        handleClick(tab) {
            // console.log(tab.props.name);
            this.activeName = tab.props.name;
            this.queryModelLibraryData();
        },
        showDialog(val) {
            this.dialogModelEditVisible = val;
            this.queryModelLibraryData();
        },
        closeDialog(val) {
            this.dialogModelEditVisible = val;
        },
        init() {
            this.queryAttributesData();
            this.queryModelLibraryData();
        },
        // 处理全选事件
        handleCheckAllChange(val) {
            let checkAllData = this.modelIndexDataList.map(value => {
                return value.id;
            });
            this.modelIndexDataList.forEach(item => {
                item.isChecked = val;
            });
            this.checkedCardIdList = val ? checkAllData : [];
            this.isIndeterminate = false;
            // console.log('id合集this.checkedCardIdList: ', this.checkedCardIdList);
        },
        // 处理卡片勾选事件
        handleModelCardCheckbox(data) {
            if (data.isChecked) {
                this.checkedCardIdList.push(data.id);
            } else {
                this.checkedCardIdList = this.checkedCardIdList.filter(item => {
                    return item != data.id;
                });
            }
            let checkedCount = this.checkedCardIdList.length;
            this.isCheckedAllModelIndex = checkedCount === this.modelIndexDataList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.modelIndexDataList.length;
        },
        // 查询体系/品牌列表数据
        queryAttributesData() {
            getAttributesGetModel()
                .then(res => {
                    let { code, setup_list } = res;
                    if (code === 200) {
                        let newResult = jsonTool(setup_list);
                        this.systemList = newResult.map(item => {
                            return {
                                id: item.id,
                                name: item.setupName,
                                materialList: item.materialList.map(item => {
                                    return {
                                        id: item.id,
                                        name: item.materialName
                                    };
                                })
                            };
                        });
                        // console.log('体系this.systemList: ', this.systemList);
                        this.brandList = newResult.brandList.map(item => {
                            return {
                                id: item.bId,
                                name: item.bName
                            };
                        });
                        // console.log('this.brandList: ', this.brandList);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 查询模型列表/待审核/问题模型数据
        queryModelLibraryData() {
            let setupIds = [];
            let materialIds = [];
            if (this.modelLibraryForm.system.length) {
                this.modelLibraryForm.system.forEach(ele => {
                    if (ele.length > 1) {
                        materialIds.push(ele[1]);
                    } else {
                        setupIds.push(ele[0]);
                    }
                });
            }
            // 1模型列表  2待审核 3问题模型
            let listType = this.activeName == 'modelIndex' ? 1 : this.activeName == 'waitAudit' ? 2 : 3;
            let startTime = this.modelLibraryForm.dateTimeArr ? this.modelLibraryForm.dateTimeArr[0] : '';
            let endTime = this.modelLibraryForm.dateTimeArr ? this.modelLibraryForm.dateTimeArr[1] : '';
            let params = {
                list_type: listType,
                search: this.modelLibraryForm.searchVal,
                setup_id: setupIds,
                material_ids: materialIds,
                brand_id: this.modelLibraryForm.brand,
                status: this.modelLibraryForm.status,
                start_time: startTime,
                end_time: endTime,
                page: this.page.page,
                limit: this.page.pageSize
            };
            getModelLibraryData(params)
                .then(res => {
                    let { code, result } = res;
                    if (code === 200) {
                        this.statusList = result.modelStatus.map(item => {
                            return {
                                id: item.status,
                                name: item.name
                            };
                        });
                        // this.statusList = this.statusList.concat(modelStatus);
                        let modelsListData = jsonTool(result.modelsList.data);
                        this.modelIndexDataList = modelsListData.map(item => {
                            return {
                                id: item.id,
                                brandId: item.brandId,
                                filePath: item.filePath,
                                modelName: item.name,
                                brandName: item.bName,
                                reason: item.reason,
                                modelUrl: item.modelUrl,
                                status: item.status,
                                system: item.setupId,
                                classification: item.classifyId,
                                material: item.sonClassifyId,
                                isChecked: false
                            };
                        });
                        this.waitAuditDataList = modelsListData.map(item => {
                            return {
                                id: item.id,
                                brandId: item.brandId,
                                filePath: item.filePath,
                                modelName: item.name,
                                brandName: item.bName,
                                reason: item.reason,
                                modelUrl: item.modelUrl,
                                status: item.status,
                                system: item.setupId,
                                classification: item.classifyId,
                                material: item.sonClassifyId
                            };
                        });
                        this.questionModelDataList = modelsListData.map(item => {
                            return {
                                id: item.id,
                                brandId: item.brandId,
                                filePath: item.filePath,
                                modelName: item.name,
                                brandName: item.bName,
                                reason: item.reason,
                                modelUrl: item.modelUrl,
                                status: item.status,
                                system: item.setupId,
                                classification: item.classifyId,
                                material: item.sonClassifyId
                            };
                        });
                        // console.log('this.questionModelDataList: ', this.questionModelDataList);
                        this.page.count = result.modelsList.total;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查询按钮
        handleSearch() {
            this.queryModelLibraryData();
        },
        // 处理上传模型
        handleUploadModel() {
            this.$router.push({
                path: '/modelUpload'
            });
        },
        // 处理生成版本配置
        handleGenerate() {
            if (!this.modelLibraryForm.brand) {
                this.$message({
                    type: 'error',
                    message: '请先选择品牌',
                    center: true
                });
                return;
            }
            this.$confirm('确认生成版本配置?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let setupIds = [];
                let materialIds = [];
                if (this.modelLibraryForm.system.length) {
                    this.modelLibraryForm.system.forEach(ele => {
                        if (ele.length > 1) {
                            materialIds.push(ele[1]);
                        } else {
                            setupIds.push(ele[0]);
                        }
                    });
                }
                let params = {
                    brand_id: this.modelLibraryForm.brand,
                    setup_id: setupIds,
                    material_ids: materialIds
                };
                modelGenerateConfig(params).then(res => {
                    let { code, msg } = res;
                    if (code === 400) {
                        this.$message({
                            type: 'error',
                            message: msg,
                            center: true
                        });
                        return;
                    } else {
                        this.$message({
                            type: 'success',
                            message: '生成版本配置成功',
                            center: true
                        });
                    }
                });
            });
        },
        // 处理启用/停用按钮事件
        handleEnableDisableBtn(type) {
            let ids = this.checkedCardIdList.join(',');
            let actionName = type === 1 ? '启用' : '禁用';
            // 启用传3  禁用传1
            let status = type === 1 ? 3 : 1;
            let params = {
                ids,
                status,
                list_type: 1
            };
            this.$confirm(`是否进行${actionName}操作？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    modelChangeStatusAction(params)
                        .then(res => {
                            let { code, msg } = res;
                            if (code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: msg,
                                    center: true
                                });
                                this.isIndeterminate = false;
                                this.isCheckedAllModelIndex = false;
                                this.checkedCardIdList = [];
                                this.queryModelLibraryData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理模型列表编辑按钮
        handleModelIndexEditBtn(data) {
            this.modelId = parseInt(data.id);
            this.list_type = 1;
            this.isEdit = true;
            // data = {
            //     ...data,
            //     activeName: this.activeName
            // };
            // this.modelEditData = data;
            //
            // this.dialogModelEditVisible = true;
        },
        handleClose() {
            this.modelId = '';
            this.isEdit = false;
            this.list_type = 1;
            this.init();
        },
        handleQuestionModelEditBtn(data) {
            this.modelId = parseInt(data.id);
            this.list_type = 3;
            this.isEdit = true;
            // data = {
            //     ...data,
            //     activeName: this.activeName
            // };
            // this.modelEditData = data;
            // this.dialogModelEditVisible = true;
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryModelLibraryData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.queryModelLibraryData();
        }
    }
};
</script>
<style scoped>
ul,
p {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

.model-library-page {
    box-sizing: border-box;
    /*padding: 15px;*/
    background-color: #fff;
    min-height: calc(100vh - 180px);
}

.model-library-page .btn {
    display: inline-block;
    width: 90px;
    height: 35px;
    background-color: rgba(85, 85, 85, 1);
    border-radius: 2px;
    color: #ffffff;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
}

.model-library-page .btn:first-child {
    margin-right: 20px;
}

.model-library-page .btn:last-child {
    margin-left: 20px;
}

.model-library-page .btn:hover {
    color: rgb(254, 154, 35);
}

.model-library-content-wrap {
    margin-top: 10px;
}

.content-action-left {
    text-align: end;
}

.content-action-left .bg-color {
    width: 90px;
    margin-left: 20px;
    background-color: rgba(85, 85, 85, 1);
}

.modelIndex-content-wrap,
.waitAudit-content-wrap,
.questionModel-content-wrap {
    margin-top: 20px;
}

.waitAudit-content-wrap .content-list,
.questionModel-content-wrap .content-list,
.modelIndex-content-wrap .content-list {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #7f7f7f;
}

.content-list .content-item {
    position: relative;
    box-sizing: border-box;
    width: 265px;
    height: 335px;
    margin: 0 21.5px 20px;
    padding: 5px 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
}

.top-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 5px;
}

.top-wrap,
.top-wrap :deep(.el-button) {
    font-size: 14px;
}

.image-wrap {
    position: relative;
    width: 235px;
    height: 215px;
    margin: 5px auto;
}

.image-wrap img {
    width: 100%;
    height: 100%;
}

.prompt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 145px;
    height: 25px;
    margin: auto;
    border: 1px solid rgb(217, 0, 27);
    line-height: 25px;
    text-align: center;
    color: rgb(217, 0, 27);
}

.enable {
    border: 1px solid #04f21c !important;
    color: #04f21c !important;
}

.bottom-wrap {
    box-sizing: border-box;
    margin-top: 15px;
    padding-left: 5px;
    font-size: 14px;
}

.bottom-wrap .model-name,
.bottom-wrap .brand-name,
.reject-reason-wrap .reason-desc {
    margin-top: 15px;
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.reject-reason-wrap {
    position: absolute;
    left: 50%;
    bottom: 70px;
    width: 237px;
    height: 70px;
    margin: 5px auto;
    border-radius: 2px;
    background-color: #fff;
    font-size: 13px;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}

.reject-reason-wrap .reject-reason {
    color: #02a7f0;
    margin-top: 10px;
}

.reject-reason-wrap .reason-desc {
    box-sizing: border-box;
    display: block;
    padding: 0 5px;
}
</style>